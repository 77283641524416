<template>
  <div>
    <div class="row">
      <div class="col-xxl-8">
        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News_category.post_detail') }} (Thai)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('News_category.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.name_th"
                trim
                placeholder="untitle"
                :state="!(post.name_th == '')"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('News_category.post_detail') }} (English)</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('News_category.title')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="post.name_en"
                trim
                placeholder="untitle"
                :state="!(post.name_en == '')"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('News_category.publish') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="สถานะ  " label-for="input-1">
              <b-form-select v-model="post.status" :options="status_list"></b-form-select>
            </b-form-group>
            <b-button variant="primary" class="mb-5" block @click="savePost" v-if="post.status == 0">Save</b-button>
            <b-button variant="success" class="mb-5" block @click="savePost" v-else>Publish</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY} from '../services.js';

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        name_th: '',
        name_en: '',
        status: 0,
      },
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'News Category', route: '../'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      if (this.$route.name == 'news_category.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then(res => {
            this.post = res.data[0];

            this.isReady = true;
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.name_th == '' || this.post.name_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.post = res.data[0];

            // this.uploadFile();
            if (this.$route.name == 'news_category.add') {
              this.$router.push({
                name: 'news_category.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'news_category.list',
              });
            }
          }
        })
        .catch(error => {
          this.isSaving = false;
          // console.log(error);
        });
    },

    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
